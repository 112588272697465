
import { defineComponent } from 'vue';
import CCarouselButtons  from '@/components/common/corousel-buttons/corousel-buttons.component.vue';
import useCarouselHook   from '@/views/login/hooks/use-login-carousel.hook';

export default defineComponent({
  name: 'CLoginCarousel',
  components: {
    CCarouselButtons,
  },
  setup() {
    const {
      currentTitle,
      currentDescription,
      marketingInfo,
      currentSlideIndex,
      switchSlide,
      setCurrentSlideIndex,
    } = useCarouselHook();

    return {
      currentTitle,
      currentDescription,
      marketingInfo,
      currentSlideIndex,
      switchSlide,
      setCurrentSlideIndex,
    };
  },
});
