
import { defineComponent, PropType } from 'vue';
import CIssueTypeFilter              from '@/views/dashboard/components/issue-type-filters/issue-type-filter.component.vue';
import { IssueFilterGroup }          from '@/views/dashboard/components/issue-type-filters/issue-type-filter.types';
import { useTranslations }           from '@/hooks/use-translations';
import { IssueByMainType }           from '@/views/dashboard/dashboard.types';


export default defineComponent({
  name: 'CIssueTypeFilterGroup',
  components: { CIssueTypeFilter },
  props: {
    filters: {
      type: Array as PropType<IssueFilterGroup[]>,
      required: true
    }
  },
  emits: ['updateParams', 'updateDeadline'],
  setup(props, { emit }) {

    const updateParams = (params: IssueByMainType): void => {
      emit('updateParams', params)
    }

    return {
      updateParams,
      useTranslations
    }
  }
})
;
