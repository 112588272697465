import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "h-formFooter w-auto sm:w-full min-w-addTaskFormMobile sm:min-w-28 sm:w-addTaskForm lg:min-w-32 bg-white flex items-center justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_button_back = _resolveComponent("c-button-back")
  const _component_c_button = _resolveComponent("c-button")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_c_button_back, {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('previous')))
    }),
    (_ctx.step!==_ctx.steps)
      ? (_openBlock(), _createBlock(_component_c_button, {
          key: 0,
          "button-label": _ctx.useGlobalTranslations( 'button.next' ),
          onClick: _ctx.onNextClick
        }, null, 8, ["button-label", "onClick"]))
      : (_openBlock(), _createBlock(_component_c_button, {
          key: 1,
          "button-label": _ctx.useGlobalTranslations( 'button.save' ),
          disabled: _ctx.isDisabledButton,
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('save')))
        }, null, 8, ["button-label", "disabled"]))
  ]))
}