import { IssueMainType } from '@/store/store.types';

export interface State {
  dashboardIssueFilters: IssueByMainType[],
  issuesByIssuesType: IssueByIssuesType[],
  employeesWithLargestAmountOfIssues: Employee[],
  loading: boolean;

}

export interface IssueByMainType {
  deadline: DeadlineType,
  mainType: IssueMainType,
  amount: number
}

export interface IssueByIssuesType {
  name: string,
  issuesDone: number,
  issueAmount: number,
  issueMaintenanceAmount: number
  issueCareAmount: number
  mainType: IssueMainType
  uuid: string
}

export interface Employee extends IssueByIssuesType{
  lastName: string
  groups: string[]

}

export enum DeadlineType {
  CLOSE_TO_DEADLINE = 'CLOSE_TO_DEADLINE',
  AFTER_DEADLINE = 'AFTER_DEADLINE'
}

export enum IssueType {
  MAINTENANCE = 'MAINTENANCE',
  CARE = 'CARE'
}



