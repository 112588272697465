
import { useTranslations }       from '@/hooks/use-translations';
import CFormInput                from '@/components/common/form/form-input/form-input.component.vue';
import CFormTextarea             from '@/components/common/form/form-textarea/form-textarea.component.vue';
import CButton                   from '@/components/common/button/button.component.vue';
import { computed, ComputedRef } from 'vue';
import { TaskModel }             from '@/views/createIssue/createIssue.types';
import { useGlobals }            from '@/hooks/use-globals';
import CLinkedIssuesSelected     from '@/views/issues/components/issues-edit/linked-issues/components/linked-issues-selected.component.vue';

export default {
  name: 'AddTaskFormStepThree',
  components: { CLinkedIssuesSelected, CFormInput, CButton, CFormTextarea },
  emits: [ 'open-side-panel', 'update-related' ],
  setup() {
    const { store } = useGlobals();
    const state = store.state.views.vCreateIssue;
    const model: ComputedRef<TaskModel> = computed(() => {
      return state.model;
    });
    return {
      useTranslations,
      model,
    };
  },
};
