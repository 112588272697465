
import { computed, ComputedRef, defineComponent } from 'vue';
import CLoginForm                               from '@/views/login/components/login-form/login-form.component.vue';
import CLoginCarousel                           from '@/views/login/components/login-carousel/login-carousel.component.vue';
import CLoginHeader                             from '@/views/login/components/login-header/login-header.component.vue';
import { useDeviceSize }                          from '@/hooks/use-page-size';
import CLoginFooterCookie                       from '@/views/login/components/login-footer-cookie/login-footer-cookie.component.vue';
import { useGlobals }                             from '@/hooks/use-globals';

export default defineComponent({
  name: 'VLogin',
  components: {
    CLoginFooterCookie,
    CLoginHeader,
    CLoginCarousel,
    CLoginForm,
  },
  setup() {
    const { isMobile } = useDeviceSize();
    const { store } = useGlobals();

    const isCookiePolicyAccepted: ComputedRef<boolean> = computed(() => store.state.views?.vLogin?.cookieAccepted || document.cookie.includes('cookiePolicy='));

    return {
      isMobile,
      isCookiePolicyAccepted,
    }
  }
});
