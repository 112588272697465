import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_form_input = _resolveComponent("c-form-input")
  const _component_c_form_radio_group = _resolveComponent("c-form-radio-group")
  const _component_c_form_select_new = _resolveComponent("c-form-select-new")
  const _component_c_form_date_picker = _resolveComponent("c-form-date-picker")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_c_form_input, {
      modelValue: $setup.model.name,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ($setup.model.name = $event)),
      error: $setup.v$.name.$error ? 'required' : null,
      focus: "",
      label: $setup.useTranslations('form.labels.name'),
      type: "text"
    }, null, 8, ["modelValue", "error", "label"]),
    _createVNode(_component_c_form_radio_group, {
      modelValue: $setup.model.priority,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ($setup.model.priority = $event)),
      class: "ml-f3 mb-1 flex flex-wrap",
      error: $setup.v$.priority.$error ? 'required' : null,
      group: $setup.priorities,
      label: $setup.useTranslations('form.labels.priority')
    }, null, 8, ["modelValue", "error", "group", "label"]),
    _createVNode(_component_c_form_select_new, {
      "model-value": $setup.model.location,
      "onUpdate:model-value": _cache[3] || (_cache[3] = ($event: any) => ($setup.model.location = $event)),
      error: $setup.v$.location.$error ? 'required' : null,
      items: $setup.issueOptions.locations,
      label: $setup.useTranslations('form.labels.location')
    }, null, 8, ["model-value", "error", "items", "label"]),
    _createVNode(_component_c_form_select_new, {
      "model-value": $setup.model.taskType,
      "onUpdate:model-value": _cache[4] || (_cache[4] = ($event: any) => ($setup.model.taskType = $event)),
      error: $setup.v$.taskType.$error ? 'required' : null,
      items: $setup.issueOptions.taskTypes,
      label: $setup.useTranslations('form.labels.type'),
      onSelected: $setup.selectGroupAndController
    }, null, 8, ["model-value", "error", "items", "label", "onSelected"]),
    _createVNode(_component_c_form_date_picker, {
      modelValue: $setup.model.tillDate,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ($setup.model.tillDate = $event)),
      class: "ml-f3 mb-1 flex flex-grow",
      "create-issue-mode": true,
      label: $setup.useTranslations('form.labels.deadline'),
      onClearDate: $setup.clearPickedDate
    }, null, 8, ["modelValue", "label", "onClearDate"])
  ], 64))
}