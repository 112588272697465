import { MutationTree } from 'vuex';
import {
  Employee,
  IssueByIssuesType,
  IssueByMainType,
  State,
} from '@/views/dashboard/dashboard.types';

export const mutations: MutationTree<State> = {
  setEmployeesWithLargestAmountOfIssues(state, employees: Employee[]): void {
    state.employeesWithLargestAmountOfIssues = employees;
  },
  setIssuesByType(state, issuesByType: IssueByIssuesType[]): void {
    state.issuesByIssuesType = issuesByType;
  },
  setDashboardIssueFilters(state, issuesByMainType: IssueByMainType[]): void {
    state.dashboardIssueFilters = issuesByMainType;
  },
  setLoading(state, isLoading: boolean): void {
    state.loading = isLoading;
  },
};
