
import { computed, ComputedRef } from 'vue';
import { useTranslations } from '@/hooks/use-translations';
import { useGlobals } from '@/hooks/use-globals';
import CFormSelectNew from '@/components/common/form/form-select/form-select.component-new.vue';
import CFormTextarea from '@/components/common/form/form-textarea/form-textarea.component.vue'
import { IssueOptions, TaskModel } from '@/views/createIssue/createIssue.types';
import {
  Expectation
} from '@/views/administration/views/administration-expectations-types/administration-expectations-types.types';
import {
  Solution
} from '@/views/administration/views/administration-solutions-types/administration-solutions-types.types';

export default {
  name: 'AddTaskFormStepFour',
  components: { CFormSelectNew, CFormTextarea },

  setup() {
    const { store } = useGlobals();
    const state = store.state.views.vCreateIssue;
    const issueOptions: ComputedRef<IssueOptions> = computed(() => store.getters['views/vCreateIssue/issueOptions'])
    const expectationType: ComputedRef<Expectation[]> = computed(() => store.state.issues.expectationType)
    const solutionType: ComputedRef<Solution[]> = computed(() => store.state.issues.solutionType)
    const model: ComputedRef<TaskModel> = computed(() => state.model)

    return {
      issueOptions,
      useTranslations,
      model,
      expectationType,
      solutionType,
    }
  }
}
