import { toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-12 text-gray-850" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_button = _resolveComponent("c-button")
  const _component_c_linked_issues_selected = _resolveComponent("c-linked-issues-selected")
  const _component_c_form_input = _resolveComponent("c-form-input")
  const _component_c_form_textarea = _resolveComponent("c-form-textarea")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("span", _hoisted_1, _toDisplayString($setup.useTranslations('form.labels.relatedIssues')), 1),
    _createVNode(_component_c_button, {
      is: "dashed",
      type: "button",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('open-side-panel')))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString($setup.useTranslations('form.labels.addTask')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_c_linked_issues_selected, {
      details: "",
      "related-issues": $setup.model.relatedIssues,
      onUpdateRelated: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update-related', $event)))
    }, null, 8, ["related-issues"]),
    _createVNode(_component_c_form_input, {
      modelValue: $setup.model.guestName,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ($setup.model.guestName = $event)),
      label: $setup.useTranslations('form.labels.guestName')
    }, null, 8, ["modelValue", "label"]),
    _createVNode(_component_c_form_textarea, {
      modelValue: $setup.model.guestDescription,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ($setup.model.guestDescription = $event)),
      label: $setup.useTranslations('form.labels.guestDescription')
    }, null, 8, ["modelValue", "label"])
  ], 64))
}