import { ComputedRef, computed }      from 'vue';
import { GlobalContexts, useGlobals } from '@/hooks/use-globals';
import { getViewStorePath }           from '@/store/store.helpers';
import { name as vLoginName }         from '@/views/login/login.route';
import { State }                      from '@/views/login/login.types';

export default () => {
  const { i18n, store }: GlobalContexts = useGlobals();
  const state: ComputedRef<State> = computed(() => store.state.views.vLogin);
  const marketingInfo: ComputedRef<string[]> = computed(() => state.value.carousel.titles);
  const currentSlideIndex: ComputedRef<number> = computed(() => state.value.carousel.currentSlideIndex);
  const currentSlideTitle: ComputedRef<string> = computed(() => marketingInfo.value[ currentSlideIndex.value ]);
  const currentTitle: ComputedRef<string> = computed(() => i18n.t(`vLogin.carouselContent.${currentSlideTitle.value}.title`)
    .toString());
  const currentDescription: ComputedRef<string> = computed(() => i18n.t(`vLogin.carouselContent.${currentSlideTitle.value}.description`)
    .toString());

  const setCurrentSlideIndex = (slideIndex: number): void => {
    if (slideIndex != null && slideIndex >= 0) {
      store.commit(`${getViewStorePath(vLoginName)}/setCurrentSlideIndex`, slideIndex);
    }
  };

  const switchSlide = (arrowRight: boolean): void => {
    let { value }: { value: number } = currentSlideIndex;

    if (arrowRight) {
      const index: number = currentSlideIndex.value + 1;
      value = marketingInfo.value[ index ] ? index : 0;
    } else {
      const index: number = currentSlideIndex.value - 1;
      value = marketingInfo.value[ index ] ? index : marketingInfo.value.length - 1;
    }

    store.commit(`${getViewStorePath(vLoginName)}/setCurrentSlideIndex`, value);
  };

  return {
    marketingInfo,
    currentSlideIndex,
    currentSlideTitle,
    currentTitle,
    currentDescription,
    setCurrentSlideIndex,
    switchSlide,
  };
};
