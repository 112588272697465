
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IconPieChart',
  props: {
    backgroundClass: {
      type: String,
      default: 'text-white'
    },
    contentClass: {
      type: String,
      default: 'text-blue-600'
    }
  }

});

