export function isRequired(value: string | number): boolean | string {
  if (value) {
    return true;
  }

  return 'required';
}

export function isLargerOrEqual(
  value: string,
  length: number
): boolean | string {
  if (value?.length >= length) {
    return true;
  }
  return 'short';
}

export function matchEachOther(
  value: string,
  matchValue: string
): boolean | string {
  if (value === matchValue) {
    return true;
  }
  return 'notMatch';
}

export function minPasswordLength(
  value: string,
  length: number
): boolean | string {
  if (value?.length >= length) {
    return true;
  }

  return 'passwordIsTooShort';
}
