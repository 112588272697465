import { createVNode as _createVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-14 flex items-center justify-between flex-wrap" }
const _hoisted_2 = { class: "flex gap-2" }
const _hoisted_3 = { class: "flex-grow flex max-w-1/2 text-white bg-transparent cursor-pointer" }
const _hoisted_4 = {
  key: 0,
  class: "text-12 text-gray-800 w-full ml-f21"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_statistic_bar = _resolveComponent("c-statistic-bar")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", {
        class: "h-8 w-8 rounded-full",
        style: { backgroundColor: _ctx.color }
      }, null, 4),
      _createVNode("span", null, _toDisplayString(_ctx.issueName), 1)
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode(_component_c_statistic_bar, {
        amount: _ctx.data.issueMaintenanceAmount,
        "issue-type": _ctx.IssueMainType.MAINTENANCE,
        ratio: _ctx.maintenanceBackgroundRatio,
        onClick: _ctx.setParam
      }, null, 8, ["amount", "issue-type", "ratio", "onClick"]),
      _createVNode(_component_c_statistic_bar, {
        amount: _ctx.data.issueCareAmount,
        "issue-type": _ctx.IssueMainType.CARE,
        ratio: _ctx.careBackgroundRatio,
        onClick: _ctx.setParam
      }, null, 8, ["amount", "issue-type", "ratio", "onClick"])
    ]),
    (_ctx.data.groups)
      ? (_openBlock(), _createBlock("div", _hoisted_4, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.data.groups, (group) => {
            return (_openBlock(), _createBlock("span", {
              key: group,
              class: ""
            }, _toDisplayString(group), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}