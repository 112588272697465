
import { computed, ComputedRef } from 'vue';
import { useTranslations } from '@/hooks/use-translations';
import CFormTextarea from '@/components/common/form/form-textarea/form-textarea.component.vue';
import CButton from '@/components/common/button/button.component.vue';
import { IssueOptions, TaskModel } from '@/views/createIssue/createIssue.types';
import { useGlobals } from '@/hooks/use-globals';
import { Group } from '@/views/administration/views/administration-groups/administration-groups.types';
import { User } from '@/views/administration/views/administration-users/administration-users.types';
import CFormSelectNew from '@/components/common/form/form-select/form-select.component-new.vue';
import IconClose from '@/components/icons/icon-close.component.vue';
import { buttonTypes } from '@/types';

export default {
  name: 'AddTaskFormStepTwo',
  components: { IconClose, CFormSelectNew, CFormTextarea, CButton },
  props: {
    mobile: {
      type: Boolean,
    },
  },
  setup() {
    const { store } = useGlobals();
    const state = store.state.views.vCreateIssue;
    const v$: ComputedRef = computed(() => {
      return state.v$
    });

    const issueOptions: ComputedRef<IssueOptions> = computed(() => store.getters['views/vCreateIssue/issueOptions']);
    const model: ComputedRef<TaskModel> = computed(() => {
      return state.model
    })
    const groups: ComputedRef<Group[]> = computed(() => {
      return state.groups
    })
    const users: ComputedRef<User[]> = computed(() => {
      return state.users;
    })
    const controllers: ComputedRef<User[]> = computed(() => {
      return state.controllers;
    })
    const onUploaded = (e: any) => {
      model.value.files = Array.from(e.target.files);
    }
    const removeFileFromModel = (index: number): void => {
      model.value.files.splice(index, 1)
    }
    return {
      controllers,
      issueOptions,
      model,
      v$,
      removeFileFromModel,
      useTranslations,
      onUploaded,
      buttonTypes
    }
  }
}
