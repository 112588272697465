import { GetterTree } from 'vuex';
import { IssueOptions, State } from '@/views/createIssue/createIssue.types';
import { RootState } from '@/store/store.types';
import { MainType } from '@/types';


export const getters: GetterTree<State, RootState> = {
  issueOptions(state): IssueOptions {
    const { locations, maintenance, care, groups, users, controllers, expectationType, solutionType } = state


    const taskTypes = state.selectedIssueType === MainType.MAINTENANCE
      ? maintenance.map(({ uuid, name }) => ({ name, value: uuid }))
      : care.map(({ uuid, name }) => ({ name, value: uuid }))


    return {
      locations: locations.map(loc => ({ value: loc.uuid, name: loc.name })),
      taskTypes,
      groups: groups.map(g => ({ value: g.uuid, name: g.name })),
      controllers: controllers.map(c => ({ value: c.uuid, name: `${c.name} ${c.lastName}` })),
      users: users.map(u => ({ value: u.uuid, name: `${u.name} ${u.lastName}` })),
      expectationType: expectationType.map((expectation) => ({ value: expectation.uuid, name: expectation.name })),
      solutionType: solutionType.map((solution) => ({ value: solution.uuid, name: solution.name }))
    }
  }
}
