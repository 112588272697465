import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-start items-center mt-f21" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_arrow_down = _resolveComponent("icon-arrow-down")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_icon_arrow_down, {
      class: "transform rotate-90 w-8 h-8 cursor-pointer text-primary-500 mr-f8",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.switchSlide(false)))
    }),
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.circlesQuantity, (circle) => {
      return (_openBlock(), _createBlock("span", {
        key: circle - 1,
        class: ["w-3 h-3 rounded-full border border-primary-500 cursor-pointer my-f3 transition duration-150 mx-f8", circle - 1 === _ctx.currentSlideIndex && 'bg-primary-500'],
        onClick: ($event: any) => (_ctx.setCurrentSlideIndex(circle -1 ))
      }, null, 10, ["onClick"]))
    }), 128)),
    _createVNode(_component_icon_arrow_down, {
      class: "transform -rotate-90 w-8 h-8 cursor-pointer text-primary-500 ml-f8",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.switchSlide(true)))
    })
  ]))
}