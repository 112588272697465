

import { computed, ComputedRef, defineComponent, PropType } from 'vue';
import { dashboardColors }                                  from '@/helpers/variables';
import { Employee, IssueByIssuesType }                      from '@/views/dashboard/dashboard.types';
import { isEmployee }                                       from '@/views/dashboard/helpers/helpers';
import { IssueMainType } from '@/store/store.types';
import CStatisticBar     from '@/views/dashboard/components/statistic-bar/statistic-bar.component.vue';

export default defineComponent({
  name: 'CIssueListItem',
  components: { CStatisticBar },
  props: {
    data: {
      type: Object as PropType<Employee | IssueByIssuesType>,
      required: true
    },
  },
  emits: ['updateParams'],
  setup(props, { emit }) {

    const maintenanceBackgroundRatio: ComputedRef<number> = computed(() => props.data.issueMaintenanceAmount ? props.data.issueMaintenanceAmount / props.data.issueAmount * 100 : 0)
    const careBackgroundRatio: ComputedRef<number> = computed(() => props.data.issueCareAmount ? props.data.issueCareAmount / props.data.issueAmount * 100 : 0)
    const issueName: ComputedRef<string> = computed(() => isEmployee((props.data)) ? `${props.data.name} ${props.data.lastName}` : `${props.data.name}`)

    const randomIndex: number = Math.floor(Math.random() * dashboardColors.length);
    const color: string = dashboardColors[randomIndex];

    const setParam = (mainType: IssueMainType): void => {
      emit('updateParams', { ...props.data, mainType });
    }

    return {
      color,
      issueName,
      setParam,
      IssueMainType,
      maintenanceBackgroundRatio,
      careBackgroundRatio,
    }
  }
});
