
import { defineComponent } from '@vue/composition-api';
import IconArrowDown       from '@/components/icons/icon-arrow-down.component.vue';

export default defineComponent({
  name: 'CCarouselButtons',
  components: {
    IconArrowDown,
  },
  props: {
    circlesQuantity: {
      type: Number,
      default: 1,
    },
    currentSlideIndex: {
      type: Number,
      default: 0,
    },

  },
  emits: ['setCurrentSlideIndex', 'switchSlide'],
  setup(props, context) {
    const setCurrentSlideIndex = (e: number): void => {
      context.emit('setCurrentSlideIndex', e);
    };

    const switchSlide = (arrowRight: boolean): void => {
      context.emit('switchSlide', arrowRight);
    };

    return {
      setCurrentSlideIndex,
      switchSlide,
    };
  },
});
