import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, Fragment as _Fragment, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "h-full w-form sm:mx-f3 lg:mx-f89 overflow-x-hidden" }
const _hoisted_2 = { class: "flex justify-between py-f13 px-f21 absolute sm:relative top-0 z-10 bg-white min-w-addTaskFormMobile sm:min-w-32 sm:w-addTaskForm" }
const _hoisted_3 = { class: "text-4xl sm:text-5xl text-mobile-title text-left leading-10 text-bold" }
const _hoisted_4 = { class: "flex flex-col mx-f13 min-w-addTaskFormMobile sm:min-w-28 sm:w-addTaskForm lg:min-w-32" }
const _hoisted_5 = { class: "flex flex-col mx-f13 min-w-addTaskFormMobile sm:min-w-28 sm:w-addTaskForm lg:min-w-32" }
const _hoisted_6 = { class: "flex flex-col mx-f13 min-w-addTaskFormMobile" }
const _hoisted_7 = {
  key: 0,
  class: "flex flex-col mx-f13 min-w-addTaskFormMobile"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_breadcrumbs = _resolveComponent("c-breadcrumbs")
  const _component_c_form_steps = _resolveComponent("c-form-steps")
  const _component_add_task_form_step_one = _resolveComponent("add-task-form-step-one")
  const _component_add_task_form_step_two = _resolveComponent("add-task-form-step-two")
  const _component_add_task_form_step_three = _resolveComponent("add-task-form-step-three")
  const _component_add_task_form_step_four = _resolveComponent("add-task-form-step-four")
  const _component_c_add_task_form_footer = _resolveComponent("c-add-task-form-footer")
  const _component_c_linked_issues = _resolveComponent("c-linked-issues")
  const _component_c_side_panel_with_background = _resolveComponent("c-side-panel-with-background")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _withDirectives(_createVNode("div", _hoisted_1, [
      (!_ctx.mobile)
        ? (_openBlock(), _createBlock(_component_c_breadcrumbs, {
            key: 0,
            class: "ml-f13"
          }))
        : _createCommentVNode("", true),
      _createVNode("div", _hoisted_2, [
        _createVNode("p", _hoisted_3, _toDisplayString(_ctx.useTranslations(`form.title.${_ctx.taskType}`)), 1),
        (_ctx.mobile)
          ? (_openBlock(), _createBlock(_component_c_form_steps, {
              key: 0,
              activeStep: _ctx.activeStep,
              "onUpdate:activeStep": _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeStep = $event)),
              class: "ml-f13",
              steps: _ctx.taskType===_ctx.Type.CARE? 4:3
            }, null, 8, ["activeStep", "steps"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode("form", {
        class: ["sm:h-addTaskFormScroll sm:overflow-y-auto overflow-x-hidden flex mt-10 sm:pb-0 sm:mt-f21\n    scrollbar-thumb-primary-500 scrollbar-thin scrollbar-track-primary-300 sm:max-w-min md:pr-f21", _ctx.formClass],
        style: {maxHeight: _ctx.fixedHeight},
        onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
      }, [
        _createVNode("div", _hoisted_4, [
          _createVNode(_component_add_task_form_step_one, {
            key: _ctx.model.priority+_ctx.model.deadline
          }),
          (!_ctx.mobile)
            ? (_openBlock(), _createBlock(_component_add_task_form_step_two, {
                key: 0,
                mobile: _ctx.mobile
              }, null, 8, ["mobile"]))
            : _createCommentVNode("", true)
        ]),
        _createVNode("div", _hoisted_5, [
          (_ctx.mobile)
            ? (_openBlock(), _createBlock(_component_add_task_form_step_two, {
                key: 0,
                mobile: _ctx.mobile
              }, null, 8, ["mobile"]))
            : _createCommentVNode("", true),
          (!_ctx.mobile)
            ? (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
                _createVNode(_component_add_task_form_step_three, {
                  onOpenSidePanel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleSidePanel(true))),
                  onUpdateRelated: _ctx.addRelatedIssues
                }, null, 8, ["onUpdateRelated"]),
                (_ctx.taskType===_ctx.Type.CARE)
                  ? (_openBlock(), _createBlock(_component_add_task_form_step_four, { key: 0 }))
                  : _createCommentVNode("", true),
                (!_ctx.mobile)
                  ? (_openBlock(), _createBlock(_component_c_add_task_form_footer, {
                      key: 1,
                      "is-disabled-button": _ctx.loading,
                      step: _ctx.activeStep,
                      onPrevious: _ctx.goToStep
                    }, null, 8, ["is-disabled-button", "step", "onPrevious"]))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        (_ctx.mobile)
          ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
              _createVNode("div", _hoisted_6, [
                _createVNode(_component_add_task_form_step_three, {
                  onOpenSidePanel: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleSidePanel(true))),
                  onUpdateRelated: _ctx.addRelatedIssues
                }, null, 8, ["onUpdateRelated"])
              ]),
              (_ctx.taskType===_ctx.Type.CARE)
                ? (_openBlock(), _createBlock("div", _hoisted_7, [
                    _createVNode(_component_add_task_form_step_four)
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true)
      ], 38),
      (_ctx.mobile)
        ? (_openBlock(), _createBlock(_component_c_add_task_form_footer, {
            key: 1,
            class: "mx-f13 absolute bottom-0",
            step: _ctx.activeStep,
            steps: _ctx.taskType===_ctx.Type.CARE? 4:3,
            onNext: _cache[5] || (_cache[5] = ($event: any) => (_ctx.goToStep(_ctx.activeStep+1))),
            onPrevious: _cache[6] || (_cache[6] = ($event: any) => (_ctx.goToStep(_ctx.activeStep-1))),
            onSave: _ctx.submitForm
          }, null, 8, ["step", "steps", "onSave"]))
        : _createCommentVNode("", true)
    ], 512), [
      [_directive_loading, _ctx.loading]
    ]),
    _createVNode(_component_c_side_panel_with_background, {
      "is-visible": _ctx.isSidePanelOpen,
      title: _ctx.useTranslations('relatedIssues'),
      onClosePanel: _ctx.toggleSidePanel
    }, {
      default: _withCtx(() => [
        _createVNode(_component_c_linked_issues, {
          "related-issues": _ctx.model.relatedIssues,
          onSave: _ctx.addRelatedIssues
        }, null, 8, ["related-issues", "onSave"])
      ]),
      _: 1
    }, 8, ["is-visible", "title", "onClosePanel"])
  ], 64))
}