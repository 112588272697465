import { FilterConfigurationFilters, IssueStatus, SelectedFilters } from '@/views/issues/issues.types';
import { reactive, Ref, ref, watch } from 'vue';
import { cloneDeep } from 'lodash-es';
import { initialFiltersConfiguration } from '@/views/issues/helpers/variables';
import { DeadlineType, Employee, IssueByIssuesType, IssueByMainType } from '@/views/dashboard/dashboard.types';
import { IssueFilterType, IssueMainType } from '@/store/store.types';
import { isEmployee, isIssueByIssuesType } from '@/views/dashboard/helpers/helpers';
import { name as vIssues } from '@/views/issues/issues.route';
import { useGlobals } from '@/hooks/use-globals';
import { useIssues, UseIssuesContext } from '@/hooks/use-issues';


interface UseDashboardFilters {
    changeIssueTypeFilter: (params: IssueByIssuesType) => void;
    changeEmployeeFilter: (params: Employee) => void;
    changeDeadlineFilter: (params: IssueByMainType) => void
}


export const useDashboardFilters = (): UseDashboardFilters => {
  const { store, router } = useGlobals()
  const { updateFiltersConfiguration } = useIssues(UseIssuesContext.ISSUES)
  const filters: SelectedFilters = reactive(cloneDeep(store.state.issues.selectedIssueFilters))

  watch(filters, async (actualFilters) => {
    await updateFiltersAndGoToIssues(actualFilters)
  })

  const updateFiltersAndGoToIssues = async (filters: SelectedFilters): Promise<void> => {
    await updateFiltersConfiguration(filters);
    await router.push({ name: vIssues, params: { initialize: 'false' } });
  }

  const changeDeadlineFilter = (params: IssueByMainType): void => {
    setInitialFiltersStatus()
    setDeadlineFilters(params)
  };

  const changeIssueTypeFilter = (params: IssueByIssuesType): void => {
    if (!isIssueByIssuesType(params)) return

    setInitialFiltersStatus();
    setIssueParams(IssueFilterType.ALL, params.mainType);
    filters.issueTypes.push(params.uuid);

  };

  const changeEmployeeFilter = (params: Employee): void => {
    if (!isEmployee(params)) return

    setInitialFiltersStatus()
    setIssueParams(IssueFilterType.ALL, params.mainType)
    filters.assignedUsers.push(params.uuid);
  };

  const setTimeParam = (deadline: DeadlineType): void => {
    switch (deadline) {
      case DeadlineType.AFTER_DEADLINE:
        store.commit('setIssueParamsTime', false);
        filters.deadlineOver = true;
        break;
      case DeadlineType.CLOSE_TO_DEADLINE:
        store.commit('setIssueParamsTime', true);
        filters.deadlineOver = false;
        break
    }
  }

  const setIssueParams = (issueFilterType: IssueFilterType, issueMainType: IssueMainType): void => {
    store.commit('setIssueParamsFilter', issueFilterType);
    store.commit('setIssueParamsType', issueMainType);
    store.commit('setIssueParamsTime', false);
  }

  const setInitialFiltersStatus = (): void => {
    filters.statuses = [IssueStatus.NEW, IssueStatus.IN_PROGRESS, IssueStatus.ASSIGNED]
  }

  const setDeadlineFilters = ({ deadline, mainType }: IssueByMainType): void => {
    setTimeParam(deadline);
    store.commit('setIssueParamsType', mainType);
    store.commit('setIssueParamsFilter', IssueFilterType.ALL);
  };


  return {
    changeEmployeeFilter,
    changeIssueTypeFilter,
    changeDeadlineFilter,

  }

}
