import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_add_task_form = _resolveComponent("c-add-task-form")
  const _component_c_main_content = _resolveComponent("c-main-content")

  return (_ctx.isMobile)
    ? (_openBlock(), _createBlock(_component_c_add_task_form, {
        key: 0,
        mobile: true,
        "task-type": _ctx.selectedTaskType,
        onCloseForm: _ctx.closeForm
      }, null, 8, ["task-type", "onCloseForm"]))
    : (_openBlock(), _createBlock(_component_c_main_content, {
        key: 1,
        class: "max-h-mainContent overflow-hidden"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_c_add_task_form, {
            mobile: false,
            "task-type": _ctx.selectedTaskType,
            onCloseForm: _ctx.closeForm
          }, null, 8, ["task-type", "onCloseForm"])
        ]),
        _: 1
      }))
}