import { toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.amount)
    ? (_openBlock(), _createBlock("div", {
        key: 0,
        class: ["h-full px-f5 min-w-min bg-red-400 flex justify-center items-center", [_ctx.issueType === _ctx.IssueMainType.CARE ? 'bg-red-400' : 'bg-green-400']],
        style: {
      width: `${_ctx.ratio}%`
    },
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click', _ctx.issueType)))
      }, [
        _createVNode("span", _hoisted_1, [
          _createTextVNode(_toDisplayString(_ctx.amount) + " ", 1),
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getIssueTypeIconComponent), { class: "h-7 w-7" }))
        ])
      ], 6))
    : _createCommentVNode("", true)
}