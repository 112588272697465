import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_form_select_new = _resolveComponent("c-form-select-new")
  const _component_c_form_textarea = _resolveComponent("c-form-textarea")

  return (_openBlock(), _createBlock(_Fragment, null, [
    ($setup.issueOptions.expectationType.length)
      ? (_openBlock(), _createBlock(_component_c_form_select_new, {
          key: 0,
          "model-value": $setup.model.expectationType,
          "onUpdate:model-value": _cache[1] || (_cache[1] = ($event: any) => ($setup.model.expectationType = $event)),
          items: $setup.issueOptions.expectationType,
          label: $setup.useTranslations('form.labels.expectationsTypes')
        }, null, 8, ["model-value", "items", "label"]))
      : _createCommentVNode("", true),
    _createVNode(_component_c_form_textarea, {
      modelValue: $setup.model.expectations,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ($setup.model.expectations = $event)),
      label: $setup.useTranslations('form.labels.expectations')
    }, null, 8, ["modelValue", "label"]),
    _createVNode(_component_c_form_select_new, {
      "model-value": $setup.model.solutionType,
      "onUpdate:model-value": _cache[3] || (_cache[3] = ($event: any) => ($setup.model.solutionType = $event)),
      items: $setup.issueOptions.solutionType,
      label: $setup.useTranslations('form.labels.solutionsTypes')
    }, null, 8, ["model-value", "items", "label"]),
    _createVNode(_component_c_form_textarea, {
      modelValue: $setup.model.solution,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ($setup.model.solution = $event)),
      label: $setup.useTranslations('form.labels.solution')
    }, null, 8, ["modelValue", "label"])
  ], 64))
}