import { Deadline, Priorities, State } from '@/views/createIssue/createIssue.types';
import { MainType } from '@/types';
import { priorityList } from '@/views/issues/helpers/variables';

export const state: State = {
  v$: {},
  selectedIssueType: MainType.MAINTENANCE,
  maintenance: [],
  care: [],
  locations: [],
  priorities: priorityList,
  deadlines: [
    { name: Deadline.TODAY, uuid: '5c85cf16-1486-11ec-82a8-0242ac130003' },
    { name: Deadline.WEEK, uuid: '5c85cfd4-1486-11ec-82a8-0242ac130003' },
    { name: Deadline.TWOWEEKS, uuid: '5c85d2b8-1486-11ec-82a8-0242ac130003' },
  ],
  groups: [],
  users: [],
  controllers: [],
  expectationType: [],
  solutionType: [],
  isLoading: false,
  model: {
    name: '',
    priority: Priorities.LOW,
    location: '',
    taskType: '',
    tillDate: '',
    deadline: '',
    group: '',
    user: '',
    controller: '',
    description: '',
    guestName: '',
    guestDescription: '',
    expectations: '',
    solution: '',
    expectationType: '',
    solutionType: '',
    relatedIssues: [],
    issueMainType: MainType.MAINTENANCE,
    files: []
  },
  taskDto: {
    name: '',
    deadline: '',
    controllerUuid: '',
    issueStatus: '',
    mainType: MainType.MAINTENANCE,
    locationUuid: '',
    description: '',
    guestName: '',
    guestDescription: '',
    priority: Priorities.LOW,
    expectations: '',
    solution: '',
    expectationType: '',
    solutionType: '',
    relatedIssues: [],
    fileUuids: [],
  },
  selectedTask: null,
  emptyModel: {
    name: '',
    priority: Priorities.LOW,
    location: '',
    taskType: '',
    tillDate: '',
    deadline: '',
    group: '',
    user: '',
    controller: '',
    description: '',
    guestName: '',
    guestDescription: '',
    expectations: '',
    solution: '',
    expectationType: '',
    solutionType: '',
    relatedIssues: [],
    issueMainType: MainType.MAINTENANCE,
    files: []
  },
  tasks: [],
}
