
import { defineComponent } from 'vue';
import { useTranslations } from '@/hooks/use-translations';
import { useLoginForm } from '@/views/login/hooks/use-login-form';
import cButton from '@/components/common/button/button.component.vue';
import iconUser from '@/components/icons/icon-user.component.vue';
import iconLock from '@/components/icons/icon-lock.component.vue';
import CLogo from '@/components/common/logo/logo.component.vue';

export default defineComponent({
  name: 'CLoginForm',
  components: {
    CLogo,
    iconLock,
    iconUser,
    cButton,
  },
  setup() {
    const {
      onSubmit,
      login,
      password,
      loginError,
      passwordError,
    } = useLoginForm();

    return {
      useTranslations,
      onSubmit,
      login,
      password,
      loginError,
      passwordError,
    };
  },
});
