import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex flex-col py-f21 leading-tight" }
const _hoisted_2 = { class: "text-18 xs:text-22 font-light text-gray-icon" }
const _hoisted_3 = { class: "text-22 xs:text-30 font-bold" }
const _hoisted_4 = { class: "flex flex-col lg:items-start lg:flex-row lg:gap-x-56 pt-f21" }
const _hoisted_5 = { class: "lg:w-1/3 overflow-auto" }
const _hoisted_6 = { class: "text-18 mb-4" }
const _hoisted_7 = { class: "lg:w-1/3" }
const _hoisted_8 = { class: "text-18 mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_issue_type_filter_group = _resolveComponent("c-issue-type-filter-group")
  const _component_c_issue_list_item = _resolveComponent("c-issue-list-item")
  const _component_c_add_task_button_group = _resolveComponent("c-add-task-button-group")
  const _component_c_main_content = _resolveComponent("c-main-content")
  const _directive_loading = _resolveDirective("loading")

  return _withDirectives((_openBlock(), _createBlock(_component_c_main_content, { class: "text-blue-navy" }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("span", _hoisted_2, _toDisplayString(_ctx.useTranslations('greetings')), 1),
        _createVNode("span", _hoisted_3, _toDisplayString(_ctx.userName), 1)
      ]),
      _createVNode(_component_c_issue_type_filter_group, {
        filters: _ctx.issuesFilters,
        onUpdateParams: _ctx.changeDeadlineFilter
      }, null, 8, ["filters", "onUpdateParams"]),
      _createVNode("div", _hoisted_4, [
        _createVNode("div", _hoisted_5, [
          _createVNode("h2", _hoisted_6, _toDisplayString(_ctx.useTranslations('issuesByType')), 1),
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.issuesByType, (issue) => {
            return (_openBlock(), _createBlock(_component_c_issue_list_item, {
              key: issue.uuid,
              class: "my-f8",
              data: issue,
              onUpdateParams: _ctx.changeIssueTypeFilter
            }, null, 8, ["data", "onUpdateParams"]))
          }), 128))
        ]),
        _createVNode("div", _hoisted_7, [
          _createVNode("h2", _hoisted_8, _toDisplayString(_ctx.useTranslations('employees')), 1),
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.employees, (employee) => {
            return (_openBlock(), _createBlock(_component_c_issue_list_item, {
              key: employee.uuid,
              class: "my-f8",
              data: employee,
              onUpdateParams: _ctx.changeEmployeeFilter
            }, null, 8, ["data", "onUpdateParams"]))
          }), 128))
        ])
      ]),
      _createVNode(_component_c_add_task_button_group)
    ]),
    _: 1
  }, 512)), [
    [_directive_loading, _ctx.loading]
  ])
}