import { ComputedRef, Ref }            from 'vue';
import { useField, useForm }           from 'vee-validate';
import { isLargerOrEqual, isRequired } from '@/helpers/rules';
import { useGlobals }                  from '@/hooks/use-globals';
import { name as vLogin }              from '@/views/login/login.route';
import { getViewStorePath }            from '@/store/store.helpers';

interface UseLoginForm {
  onSubmit: () => Promise<void>;
  login: Ref;
  password: Ref;
  loginError: ComputedRef<string | undefined>;
  passwordError: ComputedRef<string | undefined>;
}

export const useLoginForm = (): UseLoginForm => {
  const { store } = useGlobals();
  const schema: Record<string, unknown> = {
    login(value: string): boolean | string {
      return isRequired(value);
    },
    password(value: string): boolean | string {
      return isLargerOrEqual(value, 6) && isRequired(value);
    },
  };

  const { handleSubmit } = useForm({
    validationSchema: schema,
  });

  const { value: login, errorMessage: loginError } = useField(
    'login',
    {},
    { validateOnValueUpdate: false }
  );
  const { value: password, errorMessage: passwordError } = useField(
    'password',
    {},
    { validateOnValueUpdate: false }
  );

  const onSubmit: () => Promise<void> = handleSubmit(async (values) => {
    try {
      const viewModuleKeys: string[] = Object.keys(store.state.views);
      viewModuleKeys.forEach((viewKey: string) => {
        if (store.hasModule(['views', `${viewKey}`]) && viewKey !== vLogin) {
          store.unregisterModule(['views', `${viewKey}`]);
        }
      });
      await store.dispatch(`${getViewStorePath(vLogin)}/loginUser`, { login: values.login.toLowerCase(), password: values.password });
    } catch (e) {
      throw Error(e);
    }
  });

  return {
    login,
    password,
    loginError,
    passwordError,
    onSubmit,
  };
};
