import { ActionTree }       from 'vuex';
import { RootState }        from '@/store/store.types';
import { State }            from '@/views/dashboard/dashboard.types';
import { getDashboardData } from '@/service/dashboard.service';

export const actions: ActionTree<State, RootState> = {
  async getDashboardData({ commit }): Promise<void> {
    commit('setLoading', true);
    try {
      const { data } = await getDashboardData();
      commit('setEmployeesWithLargestAmountOfIssues', data.hotelUsers);
      commit('setDashboardIssueFilters', data.issuesByMainType);
      commit('setIssuesByType', data.issuesByIssuesType);
    } catch (e){
      throw e.data;
    } finally {
      commit('setLoading', false);
    }
  },
};


