import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "c-step-2" }
const _hoisted_2 = { class: "w-full" }
const _hoisted_3 = { for: "upload" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_form_select_new = _resolveComponent("c-form-select-new")
  const _component_c_form_textarea = _resolveComponent("c-form-textarea")
  const _component_icon_close = _resolveComponent("icon-close")
  const _component_c_button = _resolveComponent("c-button")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_c_form_select_new, {
      "model-value": $setup.model.group,
      "onUpdate:model-value": _cache[1] || (_cache[1] = ($event: any) => ($setup.model.group = $event)),
      error: $setup.v$.group.$error ? 'required' : null,
      items: $setup.issueOptions.groups,
      label: $setup.useTranslations('form.labels.group')
    }, null, 8, ["model-value", "error", "items", "label"]),
    _createVNode(_component_c_form_select_new, {
      "model-value": $setup.model.user,
      "onUpdate:model-value": _cache[2] || (_cache[2] = ($event: any) => ($setup.model.user = $event)),
      items: $setup.issueOptions.users,
      label: $setup.useTranslations('form.labels.user'),
      "second-label-prop": 'lastName'
    }, null, 8, ["model-value", "items", "label"]),
    _createVNode(_component_c_form_select_new, {
      "model-value": $setup.model.controller,
      "onUpdate:model-value": _cache[3] || (_cache[3] = ($event: any) => ($setup.model.controller = $event)),
      error: $setup.v$.controller.$error ? 'required' : null,
      items: $setup.issueOptions.controllers,
      label: $setup.useTranslations('form.labels.controller'),
      "second-label-prop": 'lastName'
    }, null, 8, ["model-value", "error", "items", "label"]),
    _createVNode(_component_c_form_textarea, {
      modelValue: $setup.model.description,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ($setup.model.description = $event)),
      label: $setup.useTranslations('form.labels.description')
    }, null, 8, ["modelValue", "label"]),
    _createVNode("div", _hoisted_2, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList($setup.model.files, (file, index) => {
        return (_openBlock(), _createBlock("p", {
          key: file.name,
          class: "text-12 text-gray-850 flex mb-f8"
        }, [
          _createTextVNode(_toDisplayString(file.name) + " ", 1),
          _createVNode(_component_icon_close, {
            class: "p-f5 pb-f8 w-8 h-8 p-2 cursor-pointer",
            onClick: ($event: any) => ($setup.removeFileFromModel(index))
          }, null, 8, ["onClick"])
        ]))
      }), 128)),
      _createVNode("label", _hoisted_3, [
        _createVNode(_component_c_button, {
          is: $setup.buttonTypes.DASHED,
          type: "button",
          onClick: _cache[5] || (_cache[5] = ($event: any) => ($event.target.nextSibling.click()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($props.mobile ? $setup.useTranslations('form.labels.capture') : $setup.useTranslations('form.labels.upload')), 1)
          ]),
          _: 1
        }, 8, ["is"]),
        _createVNode("input", {
          id: "upload",
          accept: "image/*",
          capture: "camera",
          class: "hidden",
          multiple: "",
          type: "file",
          onChange: _cache[6] || (_cache[6] = ($event: any) => ($setup.onUploaded($event)))
        }, null, 32)
      ])
    ])
  ]))
}