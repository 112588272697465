
import { defineComponent, PropType } from 'vue';
import { useTranslations }           from '@/hooks/use-translations';
import IconPieChart                  from '@/components/icons/icon-pie-chart.component.vue';
import { IssueByMainType }           from '@/views/dashboard/dashboard.types';


export default defineComponent({
  name: 'CIssueTypeFilter',
  components: { IconPieChart },
  props: {
    issueByType: {
      type: Object as PropType<IssueByMainType>,
      required: true
    }
  },
  emits: ['setFilterParams'],
  setup(props, { emit }) {
    const filterType = `${props.issueByType.mainType}_${props.issueByType.deadline}`;
    const setParams = (): void => {
      emit('setFilterParams', props.issueByType)
    }
    return {
      useTranslations,
      setParams,
      filterType
    }
  }
});

