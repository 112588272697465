
import { computed, ComputedRef, Ref, ref } from 'vue';
import { useTranslations } from '@/hooks/use-translations';
import { useGlobals } from '@/hooks/use-globals';
import CFormInput from '@/components/common/form/form-input/form-input.component.vue';
import CFormRadioGroup from '@/components/common/form/form-radio-group/form-radio-group.component.vue';
import CFormSelectNew from '@/components/common/form/form-select/form-select.component-new.vue';
import { IssueOptions, Priority, TaskModel } from '@/views/createIssue/createIssue.types';
import { IssueType } from '@/views/administration/views/administration-issues-types/administration-issues-types.types';
import { User } from '@/views/administration/views/administration-users/administration-users.types';
import CFormDatePicker from '@/components/common/form/form-date-picker/form-date-picker.component.vue';

export default {
  name: 'AddTaskFormStepOne',
  components: { CFormDatePicker, CFormSelectNew, CFormInput, CFormRadioGroup },
  setup() {
    const { store } = useGlobals();
    const state = store.state.views.vCreateIssue;
    const issueOptions: ComputedRef<IssueOptions> = computed(() => store.getters['views/vCreateIssue/issueOptions']);
    const model: ComputedRef<TaskModel> = computed(() => {
      return state.model;
    });
    const locations: ComputedRef<Location[]> = computed(() => {
      return state.locations;
    });
    const types: ComputedRef<IssueType[]> = computed(() => {
      return state[state.selectedIssueType.toLowerCase()];
    });
    const v$: ComputedRef<any> = computed(() => {
      return state.v$;
    });
    const priorities: Ref<Priority[]> = ref(state.priorities);

    const selectGroupAndController = (): void => {
      const type = types.value.find(type => type.uuid === model.value.taskType);
      if (!type) return
      model.value.group = type.group.uuid;
      model.value.controller = type.controller.uuid;

      const filtered = state.users.filter((user: User) => {
        if ('group' in user) {
          user.group.filter(group => group.uuid === model.value.group).length;
        }
      });

      const usersFromSelectedGroup = filtered.length ? filtered : state.users;
      store.commit('views/vCreateIssue/setUsers', usersFromSelectedGroup);
    };
    const clearPickedDate = () => {
      model.value.tillDate = '';
    }

    return {
      issueOptions,
      locations,
      model,
      types,
      priorities,
      v$,
      clearPickedDate,
      useTranslations,
      selectGroupAndController,
    };
  },
};
