import { Module }    from 'vuex';
import { RootState } from '@/store/store.types';
import { state }     from '@/views/dashboard/store/dashboard.state';
import { State }     from '@/views/dashboard/dashboard.types';
import { mutations } from '@/views/dashboard/store/dashboard.mutations';
import { actions }   from '@/views/dashboard/store/dashboard.actions';

const store: Module<State, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};

export default store;
