import { MutationTree }       from 'vuex';
import { State, TaskModel }   from '@/views/createIssue/createIssue.types';
import {
  IssueType,
}                             from '@/views/administration/views/administration-issues-types/administration-issues-types.types';
import { Group }              from '@/views/administration/views/administration-groups/administration-groups.types';
import { User }               from '@/views/administration/views/administration-users/administration-users.types';
import { Location }           from '@/views/administration/views/administration-locations/administration-locations.types';
import { cloneDeep }          from 'lodash-es';
import { MainType }           from '@/types';
import { IssueRelatedIssues } from '@/views/issues/issues.types';
import { Expectation } from '@/views/administration/views/administration-expectations-types/administration-expectations-types.types';
import { Solution } from '@/views/administration/views/administration-solutions-types/administration-solutions-types.types';

const mutations: MutationTree<State> = {
  setSelectedIssueType(state, issueType: MainType): void {
    state.selectedIssueType = issueType;
  },
  setLocations(state, data: Location[]): void {
    state.locations = data;
  },

  setIssuesTypes(state, issues: IssueType[][]): void {
    state.maintenance = issues[0];
    state.care = issues[1];
  },
  setGroups(state, groups: Group[]): void {
    state.groups = groups
  },
  setUsers(state, users: User[]): void {
    state.users = users
  },
  setControllers(state, controllers: User[]): void {
    state.controllers = controllers;
  },
  setSelectedTask(state, task: TaskModel): void {
    state.selectedTask = task;
  },
  setExpectationsTypes(state, expectationType: Expectation[]): void {
    state.expectationType = expectationType;
  },
  setSolutionsTypes(state, solutionType: Solution[]): void {
    state.solutionType = solutionType;
  },

  clearModel(state): void {
    state.model = cloneDeep(state.emptyModel);
  },
  setValidation(state, v$: any): void {
    state.v$ = v$
  },
  setRelatedIssues(state, relatedIssues: IssueRelatedIssues[]): void {
    state.model.relatedIssues = relatedIssues;
  },
  setLoading(state, isLoading: boolean) {
    state.isLoading = isLoading;
  },
}
export default mutations;
