import { Module } from 'vuex';
import { RootState } from '@/store/store.types';
import { state } from '@/views/createIssue/store/createIssue.state';
import mutations from '@/views/createIssue/store/createIssue.mutations';
import { State } from '@/views/createIssue/createIssue.types';
import actions from '@/views/createIssue/store/createIssue.actions';
import { getters } from '@/views/createIssue/store/createIssue.getters'

const store: Module<State, RootState> = {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
export default store;
