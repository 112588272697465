

import { computed, ComputedRef, defineComponent, ref } from 'vue';
import CButtonBack from '@/components/common/button/button-back.component.vue';
import CButton from '@/components/common/button/button.component.vue';
import { useGlobalTranslations } from '@/hooks/use-translations';
import { useGlobals } from '@/hooks/use-globals';

export default defineComponent({
  name: 'CAddTaskFormFooter',
  components: {
    CButton,
    CButtonBack
  },
  props: {
    step: {
      type: Number,
      required: true
    },
    steps: {
      type: Number,
      default: 1
    },
    isDisabledButton: {
      type: Boolean,
      default: false
    },
  },
  emits: ['next', 'previous', 'save'],
  setup(props, { emit }) {
    const { store } = useGlobals();
    const state = store.state.views.vCreateIssue;
    const v$: ComputedRef = computed(() => {
      return state.v$
    });

    const onNextClick = (): void => {
      v$.value.$touch();
      if (!v$.value.$error) {
        emit('next')
      }
    }

    return {
      useGlobalTranslations,
      onNextClick,
      v$
    }
  }
})
