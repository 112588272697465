
import { computed, ComputedRef, defineComponent, onMounted, onUnmounted, PropType, reactive, Ref, ref } from 'vue';
import { IssueFilterType } from '@/store/store.types';
import { MainType } from '@/types';
import { useTranslations } from '@/hooks/use-translations';
import { useGlobals } from '@/hooks/use-globals';
import { required } from '@vuelidate/validators';
import useVuelidate, { ValidationArgs } from '@vuelidate/core';
import { name as vIssues } from '@/views/issues/issues.route';
import CAddTaskFormFooter from '@/views/createIssue/components/add-task-form-footer.component.vue';
import { IssuesFormRules, TaskModel } from '@/views/createIssue/createIssue.types';
import AddTaskFormStepOne from '@/views/createIssue/components/add-task-form-step-one.component.vue';
import AddTaskFormStepTwo from '@/views/createIssue/components/add-task-form-step-two.vue';
import AddTaskFormStepThree from '@/views/createIssue/components/add-task-form-step-three.vue';
import AddTaskFormStepFour from '@/views/createIssue/components/add-task-form-step-four.vue';
import CBreadcrumbs from '@/components/common/breadcrumbs/breadcrumbs.component.vue';
import CFormSteps from '@/components/form-steps/form-steps.component.vue';
import CSidePanelWithBackground
  from '@/components/common/side-panel-with-background/side-panel-with-background.component.vue';
import CLinkedIssues from '@/views/issues/components/issues-edit/linked-issues/linked-issues.component.vue';
import { IssueRelatedIssues } from '@/views/issues/issues.types';

export default defineComponent({
  name: 'CAddTaskForm',
  components: {
    CLinkedIssues,
    CSidePanelWithBackground,
    CBreadcrumbs,
    AddTaskFormStepFour,
    AddTaskFormStepThree,
    AddTaskFormStepTwo,
    AddTaskFormStepOne,
    CAddTaskFormFooter,
    CFormSteps,
  },
  props: {
    mobile: {
      type: Boolean,
      default: true,
    },
    taskType: {
      type: String as PropType<MainType>,
      default: MainType.MAINTENANCE,
    },
  },
  emits: ['closeForm'],
  setup(props, context) {
    const { store, router } = useGlobals();
    const state = store.state.views.vCreateIssue;
    const isSidePanelOpen = ref(false);
    const fixedHeight = ref<string>('');

    const toggleSidePanel = (payload: boolean) => {
      isSidePanelOpen.value = payload;
    };

    const model: ComputedRef<TaskModel> = computed(() => state.model);

    const loading: ComputedRef<boolean> = computed(() => state.isLoading);

    const addRelatedIssues = (relatedIssues: IssueRelatedIssues[]) => {
      store.commit('views/vCreateIssue/setRelatedIssues', relatedIssues);
      toggleSidePanel(false);
    };

    const getFixedHeight = (): void => {
      const marginBottom = 20;
      const height = document.body.offsetHeight - marginBottom;
      fixedHeight.value = `${height}px`;
    };
    onMounted(() => {
      window.addEventListener('resize', getFixedHeight);
      getFixedHeight();
    });
    onUnmounted(() => {
      window.removeEventListener('resize', getFixedHeight);
    });

    const rules: ComputedRef<IssuesFormRules> = computed(() => {
      return {
        name: { required },
        priority: { required },
        location: { required },
        taskType: { required },
        group: { required },
        controller: { required }
      };
    });
    const v$ = useVuelidate((rules as unknown) as ValidationArgs, reactive(state.model) as any);
    store.commit('views/vCreateIssue/setValidation', v$);
    const activeStep: Ref<number> = ref(1);
    const formClass: Ref<string> = ref('');
    const fileUuids: Ref<string[]> = ref([]);
    const goToStep = (index: number) => {
      if (!index) {
        context.emit('closeForm');
        store.commit('views/vCreateIssue/clearModel');
        return;
      }
      activeStep.value = index;
      formClass.value = `formStep${index}`;
    };
    const getDto = () => {
      const {
        name,
        priority,
        location,
        tillDate,
        controller,
        description,
        guestName,
        guestDescription,
        expectations,
        solution,
        expectationType,
        solutionType,
        user,
        taskType,
        group,
      } = model.value;
      const mainType = state.selectedIssueType;
      return {
        controllerUuid: controller,
        deadline: tillDate,
        description,
        ...(mainType === MainType.CARE) && { expectations },
        fileUuids: fileUuids.value,
        groupUuid: group,
        guestDescription,
        guestName,
        hotelUserUuid: user,
        issueTypeUuid: taskType,
        locationUuid: location,
        mainType: mainType,
        name,
        priority,
        relatedIssues: [],
        ...(mainType === MainType.CARE) && { solution },
        ...(mainType === MainType.CARE) && { expectationTypeUuid: expectationType },
        ...(mainType === MainType.CARE) && { solutionTypeUuid: solutionType },
      };
    };
    const submitForm = async (): Promise<void> => {
      v$.value.$touch();
      if (!v$.value.$error) {
        const { files } = model.value;
        if (files.length) {
          let formData = new FormData();
          for (const file of files) {
            formData.append('files', file, file.name);
          }
          let response = await store.dispatch('views/vCreateIssue/uploadFile', formData);
          fileUuids.value = response.data;
        }
        await store.dispatch('views/vCreateIssue/addTask', getDto());
        if (props.taskType === MainType.CARE) {
          store.commit('setIssueParamsType', props.taskType);
        } else if (props.taskType === MainType.MAINTENANCE) {
          store.commit('setIssueParamsType', props.taskType);
        }
        store.commit('setIssueParamsFilter', IssueFilterType.ALL);
        await router.replace({ name: vIssues });
      }
    };

    onUnmounted(() => {
      store.commit('views/vCreateIssue/clearModel');
    })

    return {
      store,
      model,
      activeStep,
      formClass,
      Type: MainType,
      fileUuids,
      v$,
      isSidePanelOpen,
      fixedHeight,
      submitForm,
      loading,
      addRelatedIssues,
      toggleSidePanel,
      useTranslations,
      goToStep,
      getDto,
    };
  },

});

