
import { computed, ComputedRef, defineComponent } from 'vue';
import { useGlobals } from '@/hooks/use-globals';
import { useTranslations } from '@/hooks/use-translations';
import { Employee, IssueByIssuesType, IssueByMainType } from '@/views/dashboard/dashboard.types';
import CIssueListItem from '@/views/dashboard/components/common/issue-list-item/issue-list-item.component.vue';
import CIssueTypeFilterGroup
  from '@/views/dashboard/components/issue-type-filters/issue-type-filtergroup.component.vue';
import CAddTaskButtonGroup from '@/components/common/add-task-button/add-task-button-group.component.vue';
import CMainContent from '@/components/main-content/main-content.component.vue';
import { useDashboardFilters } from '@/views/dashboard/hooks/use-dashboard-filters';


export default defineComponent({
  name: 'VDashboard',
  components: {
    CIssueTypeFilterGroup,
    CIssueListItem,
    CMainContent,
    CAddTaskButtonGroup,
  },
  setup() {
    const { store } = useGlobals();
    const { changeIssueTypeFilter, changeEmployeeFilter, changeDeadlineFilter } = useDashboardFilters()
    const loading: ComputedRef<boolean> = computed(() => store.state.views.vDashboard.loading);
    const userName: ComputedRef<string> = computed(() => store.getters['user/fullUserName']);
    const employees: ComputedRef<Employee[]> = computed(() => store.state.views.vDashboard.employeesWithLargestAmountOfIssues);
    const issuesByType: ComputedRef<IssueByIssuesType[]> = computed(() => store.state.views.vDashboard.issuesByIssuesType);
    const issuesFilters: ComputedRef<IssueByMainType[]> = computed(() => store.state.views.vDashboard.dashboardIssueFilters);


    store.dispatch('views/vDashboard/getDashboardData');


    return {
      changeEmployeeFilter,
      changeIssueTypeFilter,
      employees,
      issuesByType,
      userName,
      issuesFilters,
      useTranslations,
      changeDeadlineFilter,
      loading,
    };
  },
});
