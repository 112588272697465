
import { computed, ComputedRef, defineComponent, PropType } from 'vue';
import { IssueMainType }                                    from '@/store/store.types';
import IconInsurance                                        from '@/components/icons/icon-insurance.component.vue';
import IconMaintenance                                      from '@/components/icons/icon-maintenance.component.vue';


export default defineComponent({
  name: 'CStatisticBar',
  components: { IconMaintenance, IconInsurance },
  props: {
    issueType: {
      type: String as PropType<IssueMainType>,
      required: true
    },
    ratio: {
      type: Number,
      default: 0
    },
    amount: {
      type: Number,
      default: 0
    },
  },
  emits: ['click'],
  setup(props) {
    const getIssueTypeIconComponent: ComputedRef<string> = computed(() => {
      return props.issueType === IssueMainType.CARE ? 'icon-insurance' : 'icon-maintenance'
    })

    return {
      IssueMainType,
      getIssueTypeIconComponent
    }

  }
})

