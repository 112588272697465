
import { computed, ComputedRef, defineComponent } from 'vue';
import { useGlobals }                             from '@/hooks/use-globals';
import CAddTaskForm                               from '@/views/createIssue/components/add-task-form.component.vue';
import { useDeviceSize }                          from '@/hooks/use-page-size';
import { MainType }                               from '@/types';
import CMainContent                               from '@/components/main-content/main-content.component.vue';
import { useIssues, UseIssuesContext }            from '@/hooks/use-issues';

export default defineComponent({
  name: 'VCreateIssue',
  components: { CMainContent, CAddTaskForm },
  setup() {
    const { store } = useGlobals();
    const { isMobile } = useDeviceSize();
    const { getFiltersConfiguration } = useIssues(UseIssuesContext.ISSUES);

    const selectedTaskType: ComputedRef<MainType> = computed(() => store.state.views.vCreateIssue.selectedIssueType);

    getFiltersConfiguration({ filterType: store.state.issuesParams.type });
    store.dispatch('views/vCreateIssue/getIssuesTypes');
    store.dispatch('views/vCreateIssue/getLocations');
    store.dispatch('views/vCreateIssue/getGroups');
    store.dispatch('views/vCreateIssue/getUsers');
    store.dispatch('views/vCreateIssue/getExpectationsTypes');
    store.dispatch('views/vCreateIssue/getSolutionsTypes');
    store.dispatch('issues/getExpectationsTypes');
    store.dispatch('issues/getSolutionsTypes');

    const closeForm = async (): Promise<void> => { history.back() };

    return {
      isMobile,
      selectedTaskType,
      closeForm,
    };
  },
});
